body {
  font-family: system-ui, -apple-system, BlinkMacSystemFont, 'Segoe UI', Roboto, Oxygen, Ubuntu, Cantarell, 'Open Sans', 'Helvetica Neue', sans-serif;
  background-color: #ffffff;
  color: #333;
  line-height: 1.6;
  margin: 0;
  padding: 0;
  text-align: justify;
}

.App, .books-page, .page {
  max-width: 600px;
  margin: 0 auto;
  padding: 20px;
}

h1, h2, h3, h4, h5, h6 {
  font-weight: 600;
}

a {
  color: inherit;
  text-decoration: underline;
}

a:hover {
  text-decoration: underline;
}

.header-container, .site-title {
  text-align: center;
  margin-bottom: 20px;
}

.header-container h1 {
  margin: 0;
}

.header-container a {
  text-decoration: none;
  color: inherit;
}

.site-title h1 {
  margin: 0;
  font-size: 48px;
  font-weight: lighter;
  color: #444444;
}

.site-title h2 {
  margin: 10px 0 0;
  font-size: 24px;
  font-weight: normal;
  color: #666666;
}

.site-title a {
  text-decoration: none;
  color: inherit;
}

.header-content h1 {
  font-size: 48px;
  margin-bottom: 10px;
  text-align: center;
  color: #444444;
  font-weight: lighter;
}

.header-content p {
  font-size: 15px;
  color: #444444;
  text-align: justify;
}

.currently-listening {
  margin-bottom: 10px;
}

.currently-listening h3 {
  font-size: 18px;
  margin-bottom: 10px;
  font-weight: 450;
  color: #CCCCCC;
  border-bottom: 1.5px dashed #CCCCCC;
}

.currently-listening p {
  font-size: 15px;
  color: #444444;
}

section {
  margin-bottom: 40px;
}

section h2 {
  font-size: 1.8rem;
  margin-bottom: 20px;
}

ul {
  list-style-type: none;
  padding: 0;
}

li {
  margin-bottom: 10px;
}

.footer-container {
  margin-top: 40px;
  text-align: center;
  color: #666;
  font-size: 0.9rem;
}

.reading-list-container h2{
  font-size: 18px;
  font-weight: 450;
  color: #CCCCCC;
  border-bottom: 1.5px dashed #CCCCCC;
}

.reading-list-container ul {
  columns: 2;
  column-gap: 20px;
  font-size: 15px;
  color: #444444;
}
.reading-list-container-sub ul {
  columns: 1;
  column-gap: 20px;
  font-size: 15px;
  color: #444444;
}

.reading-list-container-sub h2{
  font-size: 18px;
  font-weight: 450;
  color: #CCCCCC;
  border-bottom: 1.5px dashed #CCCCCC;
}

.reading-list-container-sub ul li {
  break-inside: avoid-column;
  margin-bottom: 10px;
}

.reading-list-container ul li {
  break-inside: avoid-column;
  margin-bottom: 10px;
}

.book-categories {
  list-style-type: none;
  padding-left: 0;
}

.book-categories > li {
  margin-bottom: 15px;
  font-weight: bold;
}

.book-categories > li > ul {
  margin-top: 5px;
  padding-left: 20px;
}

.book-categories > li > ul > li {
  font-weight: normal;
}

.book-categories ul {
  list-style-type: disc;
}

.completed-books-section {
  columns: 1;  /* Creates 3 columns */
}

.category-section {
  break-inside: avoid; /* Prevents categories from splitting across columns */
  margin-bottom: 30px;
}

.category-section h3 {
  text-align: center;
  color: #CCCCCC;
  font-weight: 450;
  font-size: 18px;
  border-bottom: 1.5px dashed #CCCCCC;
  padding-bottom: 10px;
  margin-bottom: 20px;
}

.category-section ul {
  list-style: none;
  padding: 0;
  margin: 0;
}

.category-section li {
  margin-bottom: 10px;
}
